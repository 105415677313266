import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import i18n from 'i18next';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { debounce } from 'lodash';

import { handleIOSMsg } from '../../../utils';
import ConfirmationDialog from '../../custom/ConfirmationDialog';
import {
  alert,
  businesses,
  checkout,
  memberships,
  organizations,
} from '../../../state';
import CustomInputField from '../../custom/CustomInputField';
import { CustomImgCard } from '../../custom';
// import listOfStates from '../../../../misc/listOfStates.json';
import MembershipRegisterSchema from './MembershipRegisterSchema';
import './styles.css';
import SponsorImg from './SponsorImg';
import PhoneInput from '../../custom/PhoneInput';
import { countries } from '../../../utils/constants';
import MembershipFeeDetails from './MembershipFeeDetails';
import CheckoutClover from '../Ticket/CheckoutClover';

const appId = process.env.GATSBY_APLICATION_ID;
const locationId = process.env.GATSBY_LOCATION_ID;
const sandbox = process.env.GATSBY_SANDBOX_SQUARE_PAYMENT_FORM;

const MembershipRegisterForm = ({ formData, setFormData, openAlert }) => {
  const { t } = useTranslation();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const { partner } = useSelector(businesses.selectors.selectBusiness);
  const useStyles = makeStyles(() => ({
    paymentCard: {
      overflow: 'visible',
      position: 'relative',
      backgroundColor: membershipConfig.brandColors?.formColor || '#fff',
    },
    paymentHeader: {
      position: 'absolute',
      backgroundColor: membershipConfig.brandColors?.formColor || '#fff',
      top: '-20px',
      left: '10px',
      zIndex: 1,
      padding: '0 5px',
    },
  }));
  const classes = useStyles();
  const isLoading = useSelector(memberships.selectors.selectIsLoading);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const plans = membershipConfig.plans
    ? membershipConfig.plans.filter((p) => p.status === 1)
    : [];

  const calculateServiceFee = (amount) => {
    if (membershipConfig?.id) {
      if (
        !membershipConfig.enableRecurringCost ||
        !membershipConfig.recurringCost
      ) {
        return 0;
      }
      if (membershipConfig.recurringCostType === 0) {
        return (amount * membershipConfig.recurringCost) / 100;
      }
      if (membershipConfig.recurringCostType === 1) {
        return membershipConfig.recurringCost;
      }
    }
    return 0;
  };
  const calculateOneTimeCost = (amount) => {
    if (membershipConfig?.id) {
      if (
        !membershipConfig.enableOneTimeCost ||
        !membershipConfig.oneTimeCost
      ) {
        return 0;
      }
      if (membershipConfig.oneTimeCostType === 0) {
        return (amount * membershipConfig.oneTimeCost) / 100;
      }
      if (membershipConfig.oneTimeCostType === 1) {
        return membershipConfig.oneTimeCost;
      }
    }
    return 0;
  };

  const calculateTotalAmount = (amount) => {
    if (membershipConfig?.id) {
      if (membershipConfig.hasTrial && !membershipConfig.trialDays > 0) {
        return 0;
      }
      const extraFee =
        calculateServiceFee(amount) + calculateOneTimeCost(amount);
      return membershipConfig.useTax
        ? amount + (amount * membershipConfig.tax) / 100 + extraFee
        : amount + extraFee;
    }
    return amount;
  };
  const calculateMonthlyAmount = (amount) => {
    if (membershipConfig?.id) {
      if (membershipConfig.hasTrial && !membershipConfig.trialDays > 0) {
        return 0;
      }
      return (
        amount +
        calculateServiceFee(amount) +
        (membershipConfig.useTax ? (amount * membershipConfig.tax) / 100 : 0)
      );
    }
    return amount;
  };
  const currencySymbol = getSymbolFromCurrency(plans?.[0]?.currency || 'USD');
  const listOfMonths = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 },
  ];
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [isRegistering, setIsRegistering] = React.useState(false);
  const [duplicateModal, setDuplicateModal] = React.useState(false);
  const [allowDuplicateEmail, setAllowDuplicateEmail] = React.useState(false);
  const [allowDuplicateCode, setAllowDuplicateCode] = React.useState(false);
  const [registerData, setRegisterData] = React.useState({});

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down(817));

  // eslint-disable-next-line consistent-return
  const membershipRegister = async (details, buyer) => {
    const response = await dispatch(
      memberships.actions.register({
        ...formikRef.current.values,
        nonce: details?.token,
        verificationToken: buyer?.token,
        expMonth: details?.card?.exp_month,
        expYear: details?.card?.exp_year,
        phone:
          countries.find((c) => c.code === formikRef.current.values.countryCode)
            ?.number + formikRef.current.values.localPhone,
        allowDuplicateEmail,
        allowDuplicateCode,
      })
    );

    if (response.error) {
      if (response.payload?.message === 'DuplicateEmail') {
        await setRegisterData({ details, buyer });
        await setDuplicateModal('email');
        setIsRegistering(false);
        return;
      }
      if (response.payload?.message === 'DuplicateCode') {
        await setRegisterData({ details, buyer });
        await setDuplicateModal('code');
        setIsRegistering(false);
        return;
      }
      handleIOSMsg('payment_failed');
      openAlert({
        message:
          response.payload?.message ||
          t('weCannotProcessPaymentsPleaseTryLater'),
        severity: 'error',
      });
      setIsRegistering(false);
    } else if (response.payload) {
      setIsConfirm(true);
    } else {
      setIsRegistering(false);
    }
  };

  useEffect(() => {
    if (plans?.[0]) {
      setFormData({
        ...formData,
        customerPaysAmount: calculateTotalAmount(plans[0].amount).toString(),
        recurringCost: calculateServiceFee(plans[0].amount).toString(),
        oneTimeCost: calculateOneTimeCost(plans[0].amount).toString(),
        totalAmount: calculateMonthlyAmount(plans[0].amount).toString(),
        currency: plans[0].currency || 'USD',
        amount: plans[0].amount,
      });
    }
  }, [membershipConfig]);
  React.useEffect(() => {
    if (formData?.culture) {
      i18n.changeLanguage(formData.culture);
    }
  }, [formData]);

  useEffect(() => {
    if (allowDuplicateCode === true) {
      setIsRegistering(true);
      membershipRegister(registerData?.details, registerData?.buyer);
    }
  }, [allowDuplicateCode]);

  useEffect(() => {
    if (allowDuplicateEmail === true) {
      setIsRegistering(true);
      membershipRegister(registerData?.details, registerData?.buyer);
    }
  }, [allowDuplicateEmail]);

  const cardNonceResponseReceived = async (details, buyer) => {
    setIsRegistering(true);
    try {
      if (details.status !== 'OK') {
        setIsRegistering(false);
        return;
      }
      await setFormData(formikRef.current.values);
      await membershipRegister(details, buyer);
    } catch (e) {
      setIsRegistering(false);
    }
  };

  const [plan, setPlan] = useState(null);

  const onPlanChange = (val, setFieldValue) => {
    const selectedPlan = plans.find((p) => p.id === val.props.value) || {};
    setFieldValue('amount', selectedPlan.amount || 0);
    setFieldValue('period', selectedPlan.period || '');
    setFieldValue('planId', selectedPlan.id || '');
    setPlan(selectedPlan || '');
    setFormData({
      ...formData,
      customerPaysAmount: calculateTotalAmount(selectedPlan.amount || 0),
      recurringCost: calculateServiceFee(selectedPlan.amount || 0),
      oneTimeCost: calculateOneTimeCost(selectedPlan.amount || 0),
      totalAmount: calculateMonthlyAmount(selectedPlan.amount).toString(),
      amount: (selectedPlan.amount || 0).toFixed(2),
      currency: selectedPlan.currency,
      planId: selectedPlan.id,
    });
  };

  useEffect(() => {
    if (
      (!membershipConfig.allowSelectPlan || plans.length === 1) &&
      membershipConfig.plans
    )
      setPlan(plans[0]);

    if (membershipConfig?.country) {
      const country = countries.find(
        (c) => c.code === membershipConfig.country
      );
      if (country) {
        formikRef?.current?.setFieldValue('countryCode', country.code);
      }
    }
  }, [membershipConfig, plans]);

  const createVerificationDetails = () => ({
    amount: formData.customerPaysAmount,
    currencyCode: formData.currency || 'USD',
    intent: 'STORE',
    billingContact: {
      familyName: formData.lastName,
      givenName: formData.firstName,
      email: formData.email,
    },
  });
  const validateMembershipCode = async (code, ctx) => {
    const res = await dispatch(
      memberships.actions.validateMembershipCode({
        code,
        businessId: membershipConfig.businessId,
      })
    );
    if (res.error) {
      return ctx.createError({
        message: res.payload?.message || 'Invalid membership #',
        path: 'code',
      });
    }
    return true;
  };
  const debouncedValidateMembershipCode = debounce(validateMembershipCode, 300);
  const isSquareCheckout = partner === 'Square';
  const isCloverCheckout = partner === 'Clover';

  return (
    <>
      <Box
        borderRadius={20}
        border="1px solid #ccc"
        p={5}
        mb={4}
        bgcolor={membershipConfig.brandColors?.formColor || '#fff'}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} justifyContent="center">
              <Box maxWidth={300} margin="auto">
                {membershipConfig.hasSponsor === true && (
                  <Box mt={1} mb={2}>
                    <Typography
                      variant="body2"
                      style={{
                        textDecoration: 'underline grey',
                        color: 'grey',
                        textAlign: 'center',
                      }}
                    >
                      {membershipConfig.sponsorText}
                    </Typography>
                    <SponsorImg
                      imageUrl={membershipConfig.sponsorImage}
                      alt={membershipConfig.name}
                    />
                  </Box>
                )}
                <CustomImgCard
                  isLoading={isLoading}
                  name={membershipConfig.businessName}
                  imageUrl={plan?.imageUrl || membershipConfig.imageUrl}
                />
              </Box>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Box py={3}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="h4" align="center">
                        {plan?.id
                          ? `${plan.name} for ${t('cardValue', {
                              amount: formData.totalAmount,
                              formatParams: {
                                amount: { currency: formData.currency },
                              },
                            })}${
                              plan.period === 'CUSTOM'
                                ? ''
                                : `/${plan.period?.toLowerCase()}`
                            }`
                          : t('membership.signUpForm')}
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: 80 }}>
                      <MembershipFeeDetails
                        formData={formData}
                        membershipConfig={membershipConfig}
                      />
                    </Grid>
                  </Grid>
                  {membershipConfig.hasTrial && membershipConfig.trialDays > 0 && (
                    <Typography variant="h5" align="center">
                      (
                      {t('membership.trialDays', {
                        trialDays: membershipConfig.trialDays,
                      })}
                      )
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <Box>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Box>
              ) : (
                <Formik
                  innerRef={formikRef}
                  validationSchema={() =>
                    MembershipRegisterSchema(
                      membershipConfig.showAddressInput,
                      debouncedValidateMembershipCode
                    )
                  }
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    localPhone: '',
                    countryCode: '',
                    address: '',
                    state: '',
                    city: '',
                    zipcode: '',
                    amount: undefined,
                    birthMonth: undefined,
                    period: '',
                    code: '',
                    numberType: membershipConfig.numberType,
                    captureBirthMonth: membershipConfig.captureBirthMonth,
                    hasLoyalty: membershipConfig.hasLoyalty,
                    customQuestion: membershipConfig.customQuestion,
                    questions: membershipConfig.questions?.length
                      ? membershipConfig.questions.map((q) => ({
                          ...q,
                          note: '',
                        }))
                      : [{ note: '' }],
                    planId:
                      (!membershipConfig.allowSelectPlan &&
                        membershipConfig.plans &&
                        membershipConfig.plans[0]?.id) ||
                      membershipConfig?.plans?.length === 1
                        ? membershipConfig.plans[0].id
                        : '',
                    autoRenewal:
                      plan?.period !== 'CUSTOM' && membershipConfig.autoRenewal,
                  }}
                  onSubmit={(values, actions) => {
                    actions.setFieldValue(
                      'phone',
                      countries.find((c) => c.code === values.countryCode)
                        ?.number + values.localPhone
                    );
                    actions.setSubmitting(true);
                    setIsRegistering(true);
                    setFormData({
                      ...values,
                      code: values.code.toString(),
                      phone:
                        countries.find((c) => c.code === values.countryCode)
                          ?.number + values.localPhone,
                    });
                    if (formData.customerPaysAmount === '0') {
                      membershipRegister();
                    }
                    actions.setSubmitting(false);
                  }}
                >
                  {({
                    setFieldValue,
                    values,
                    errors,
                    handleSubmit,
                    isSubmitting,
                  }) => {
                    const onSubmit = async (result) => {
                      await handleSubmit();
                      await setFormData(formikRef.current.values);
                      await membershipRegister(result, '');
                    };

                    const autoRenewalBlock = (
                      <Typography
                        variant="span"
                        style={{
                          position: 'absolute',
                          top: 125,
                          right: 10,
                          zIndex: 1,
                        }}
                      >
                        <Field
                          type="checkbox"
                          component={Checkbox}
                          name="autoRenewal"
                          color="primary"
                          onChange={() => {
                            setFieldValue('autoRenewal', !values.autoRenewal);
                          }}
                          checked={values.autoRenewal}
                          disabled={membershipConfig.autoRenewal}
                        />
                        {t('membership.autoRenewal')}
                      </Typography>
                    );

                    return (
                      <Form margin="dense" size="small">
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          alignItems="stretch"
                        >
                          <Grid item xs={12}>
                            <Box pb={2}>
                              {membershipConfig.numberType === 2 && (
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="flex-start"
                                  spacing={2}
                                >
                                  <Grid item xs={12} md={6}>
                                    <CustomInputField
                                      customInput={Field}
                                      component={FormikTextField}
                                      capitalize
                                      fullWidth
                                      required
                                      label={t('membership.number')}
                                      name="code"
                                      removeArrows
                                      variant="outlined"
                                      // onBlur={(e) => {
                                      //   if (!errors.code) {
                                      //     validateMembershipCode(
                                      //       e.target.value,
                                      //       ctx
                                      //     );
                                      //   }
                                      // }}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    capitalize
                                    fullWidth
                                    required
                                    label={t('yourFirstName')}
                                    name="firstName"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    capitalize
                                    fullWidth
                                    required
                                    label={t('yourLastName')}
                                    name="lastName"
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box pb={2}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item xs={12} md={6}>
                                  <PhoneInput
                                    value={values.countryCode}
                                    onChange={(val) => {
                                      setFieldValue('phone', val);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <CustomInputField
                                    customInput={Field}
                                    component={FormikTextField}
                                    trim
                                    fullWidth
                                    required
                                    label={t('yourEmail')}
                                    name="email"
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          {membershipConfig.showAddressInput ? (
                            <>
                              <Box pb={2}>
                                <CustomInputField
                                  customInput={Field}
                                  component={FormikTextField}
                                  fullWidth
                                  required
                                  label={t('yourAddress')}
                                  name="address"
                                  variant="outlined"
                                />
                              </Box>
                              <Box pb={2}>
                                <Grid container display="row" spacing={2}>
                                  <Grid
                                    item
                                    xs={
                                      membershipConfig.captureBirthMonth ? 6 : 5
                                    }
                                  >
                                    <Field
                                      component={FormikTextField}
                                      label="City"
                                      name="city"
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={
                                      membershipConfig.captureBirthMonth ? 6 : 4
                                    }
                                  >
                                    <Field
                                      component={FormikTextField}
                                      label="State / Province"
                                      name="state"
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={
                                      membershipConfig.captureBirthMonth ? 6 : 3
                                    }
                                  >
                                    <Field
                                      component={FormikTextField}
                                      label="Zip / Pin"
                                      name="zipcode"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      InputProps={{
                                        inputProps: {
                                          maxLength: '5',
                                        },
                                      }}
                                    />
                                  </Grid>
                                  {membershipConfig.captureBirthMonth && (
                                    <Grid item xs={6}>
                                      <Field
                                        component={FormikTextField}
                                        label={t('birthMonth')}
                                        select
                                        name="birthMonth"
                                        variant="outlined"
                                        fullWidth
                                      >
                                        {listOfMonths.map((month) => (
                                          <MenuItem
                                            key={month.value}
                                            value={month.value}
                                            onClick={() =>
                                              setFieldValue(
                                                'birthMonth',
                                                month.value
                                              )
                                            }
                                          >
                                            {month.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </Grid>
                                  )}
                                </Grid>
                              </Box>
                            </>
                          ) : (
                            membershipConfig.captureBirthMonth && (
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Field
                                    component={FormikTextField}
                                    label={t('birthMonth')}
                                    select
                                    name="birthMonth"
                                    variant="outlined"
                                    fullWidth
                                  >
                                    {listOfMonths.map((month) => (
                                      <MenuItem
                                        key={month.value}
                                        value={month.value}
                                        onClick={() =>
                                          setFieldValue(
                                            'birthMonth',
                                            month.value
                                          )
                                        }
                                      >
                                        {month.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </Grid>
                              </Grid>
                            )
                          )}

                          {membershipConfig.customQuestion &&
                            membershipConfig.questions.map((q, index) => (
                              <Box pb={2} key={q.title}>
                                <Field
                                  component={FormikTextField}
                                  label={q.title}
                                  name={`questions[${index}].note`}
                                  variant="outlined"
                                  required
                                  fullWidth
                                />
                                <Box hidden>
                                  <Field
                                    component={FormikTextField}
                                    type="hidden"
                                    style={{ display: 'hidden' }}
                                    name={`questions[${index}].title`}
                                    value={q.title}
                                    variant="outlined"
                                  />
                                </Box>
                              </Box>
                            ))}
                          {membershipConfig.allowSelectPlan &&
                            membershipConfig?.plans?.length !== 1 && (
                              <Grid item xs={12}>
                                <Box mb={2} mt={4}>
                                  <Card
                                    variant="outlined"
                                    className={classes.paymentCard}
                                  >
                                    <CardHeader
                                      title={t('selectPlan')}
                                      className={classes.paymentHeader}
                                    />
                                    <CardContent>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                          <Field
                                            component={Select}
                                            as="select"
                                            autoWidth={false}
                                            fullWidth
                                            label={t(
                                              'membership.planNameToIssue'
                                            )}
                                            name="planId"
                                            variant="outlined"
                                            onChange={(event, val) =>
                                              onPlanChange(val, setFieldValue)
                                            }
                                            style={{ height: '47px' }}
                                          >
                                            <MenuItem value={undefined}>
                                              ---
                                            </MenuItem>
                                            {plans.map((opt) => (
                                              <MenuItem
                                                value={opt.id}
                                                key={opt.id}
                                              >
                                                {opt.name}
                                              </MenuItem>
                                            ))}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <CustomInputField
                                            customInput={Field}
                                            component={TextField}
                                            fullWidth
                                            disabled
                                            startAdornment={currencySymbol}
                                            type="number"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            label={t('membership.price')}
                                            value={values.amount}
                                            name="amount"
                                            variant="outlined"
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <CustomInputField
                                            customInput={Field}
                                            component={TextField}
                                            fullWidth
                                            disabled
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            label={t('membership.period')}
                                            value={values.period}
                                            name="period"
                                            variant="outlined"
                                          />
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </Grid>
                            )}
                          {/* eslint-disable-next-line eqeqeq */}
                          {formData.customerPaysAmount == 0 ? (
                            <>
                              {/* <Box>{autoRenewalBlock}</Box> */}
                              <Grid
                                item
                                xs={12}
                                container
                                alignItems="center"
                                justifyContent="center"
                                direction="row"
                              >
                                <Box mb={2}>
                                  <Button
                                    onClick={handleSubmit}
                                    color="primary"
                                    variant="contained"
                                    textAlign="center"
                                    size="large"
                                    disabled={
                                      isLoading ||
                                      isSubmitting ||
                                      isRegistering ||
                                      !values?.planId
                                    }
                                  >
                                    {isLoading ? (
                                      <CircularProgress size={24} />
                                    ) : (
                                      t('signUp')
                                    )}
                                  </Button>
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <Box mb={2} mt={4}>
                                <Card
                                  variant="outlined"
                                  className={classes.paymentCard}
                                >
                                  <CardHeader
                                    title={t('paymentDetails')}
                                    className={classes.paymentHeader}
                                  />
                                  <CardContent>
                                    <Box
                                      pt={1}
                                      style={{ position: 'relative' }}
                                    >
                                      {isSquareCheckout && (
                                        <PaymentForm
                                          sandbox={sandbox === 'true'}
                                          applicationId={appId}
                                          locationId={locationId}
                                          cardTokenizeResponseReceived={
                                            cardNonceResponseReceived
                                          }
                                          createVerificationDetails={
                                            createVerificationDetails
                                          }
                                        >
                                          <Typography
                                            variant="span"
                                            style={{
                                              position: 'absolute',
                                              top: md ? 108 : 58,
                                              right: 10,
                                              zIndex: 1,
                                            }}
                                          >
                                            <Field
                                              type="checkbox"
                                              component={Checkbox}
                                              name="autoRenewal"
                                              color="primary"
                                              onChange={() => {
                                                setFieldValue(
                                                  'autoRenewal',
                                                  !values.autoRenewal
                                                );
                                              }}
                                              checked={values.autoRenewal}
                                              disabled={
                                                membershipConfig.autoRenewal
                                              }
                                            />
                                            {t('membership.autoRenewal')}
                                          </Typography>
                                          <CreditCard
                                            buttonProps={{
                                              isLoading:
                                                isLoading ||
                                                isSubmitting ||
                                                Object.keys(errors).length ||
                                                isRegistering,
                                            }}
                                          >
                                            {membershipConfig.hasTrial &&
                                            membershipConfig.trialDays > 0 ? (
                                              'Sign Up'
                                            ) : (
                                              <>
                                                Pay (Total ={' '}
                                                {t('cardValue', {
                                                  amount:
                                                    formData.customerPaysAmount,
                                                  formatParams: {
                                                    amount: {
                                                      currency:
                                                        formData.currency,
                                                    },
                                                  },
                                                })}
                                                )
                                              </>
                                            )}
                                          </CreditCard>
                                        </PaymentForm>
                                      )}
                                      {isCloverCheckout && (
                                        <Box
                                          pt={1}
                                          style={{ position: 'relative' }}
                                        >
                                          <CheckoutClover
                                            onSubmit={onSubmit}
                                            formData={formData}
                                          >
                                            <>
                                              <Box mt={5} />
                                              {autoRenewalBlock}
                                            </>
                                          </CheckoutClover>
                                        </Box>
                                      )}
                                    </Box>
                                  </CardContent>
                                </Card>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                        <Box my={3}>
                          <Typography style={{ whiteSpace: 'pre-wrap' }}>
                            {membershipConfig.terms}
                          </Typography>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationDialog
        open={isConfirm}
        setOpen={setIsConfirm}
        isConfirmation={false}
        title="Success!"
        content={t('membership.successSignUp')}
        actionOk={() => {
          setTimeout(() => window.location.reload(), 100);
        }}
      />

      <ConfirmationDialog
        open={duplicateModal === 'email'}
        setOpen={setDuplicateModal}
        isConfirmation
        title="Warning"
        content="This email address has already been registered for membership. Do you still want to continue?"
        actionOk={() => {
          setAllowDuplicateEmail(true);
        }}
      />

      <ConfirmationDialog
        open={duplicateModal === 'code'}
        setOpen={setDuplicateModal}
        isConfirmation
        title="Warning"
        content="This phone number has been registered for membership #. Do you still want to continue?"
        actionOk={async () => {
          setAllowDuplicateCode(true);
        }}
      />
    </>
  );
};

MembershipRegisterForm.propTypes = {
  formData: PropTypes.shape({
    totalAmount: PropTypes.string,
    customerPaysAmount: PropTypes.string,
    promoCode: PropTypes.string,
    isGift: PropTypes.bool,
    organizationId: PropTypes.string,
    businessId: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    city: PropTypes.string,
    line1: PropTypes.string,
    postal_code: PropTypes.string,
    orgCode: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.string,
    country: PropTypes.string,
    code: PropTypes.string,
    culture: PropTypes.string,
    plan: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        note: PropTypes.string,
      })
    ),
    autoRenewal: PropTypes.bool,
    recurringCost: PropTypes.number,
    oneTimeCost: PropTypes.number,
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  formData: checkout.selectors.selectFormData(state),
  organization: organizations.selectors.selectOrganization(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFormData: (payload) => dispatch(checkout.actions.setFormData(payload)),
  squareCheckout: (payload) =>
    dispatch(checkout.actions.squareCheckout(payload)),
  openAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipRegisterForm);
